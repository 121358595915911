import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { Input } from '../../../components/shared/form-control/Input';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import AuthService from '../../../services/AuthService';
import { isEmailValid } from '../../../utils/EmailUtils';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';

const ForgotPassword: FC = () => {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  const toasts = useToasts();
  const navigate = useNavigate();
  const { t } = useTranslation('auth');

  const doRequestReset = useCallback(() => {
    setLoading(true);

    AuthService.forgotPassword(username).then(() => {
      toasts.addToast({
        title: t('forgot-password.toasts.success.title'),
        description: t('forgot-password.toasts.success.description'),
        type: ToastType.INFO,
        expiresInMs: 10000,
      });
      navigate('/auth/login');
    });
  }, [navigate, t, toasts, username]);

  const valid = useMemo(() => !!username && isEmailValid(username), [username]);

  const detectSubmit = useCallback(
    (e: KeyboardEvent) => {
      if (valid && e.key === 'Enter') {
        doRequestReset();
      }
    },
    [doRequestReset, valid],
  );

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('forgot-password.heading')}</Heading>
      <div>{t('forgot-password.subheading')}</div>
      <div className="w-96">
        <Input
          autocomplete="email"
          name="email"
          id="email"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder={t('forgot-password.inputs.email')}
          label={t('forgot-password.inputs.email')}
          aria-label={t('forgot-password.inputs.email')}
          type="email"
          onKeyPress={detectSubmit}
          data-cy="forgot-password-email"
        />

        <div className="px-10 text-center">
          <Button
            className="mt-6 w-full"
            type={ButtonType.PRIMARY}
            onClick={doRequestReset}
            disabled={loading || !valid}
            data-cy="forgot-password-submit"
          >
            <span className="font-medium">{t('forgot-password.buttons.continue')}</span>
          </Button>
          <div className="mt-4 text-center font-medium">
            <span className="cursor-pointer hover:underline" data-cy="back-link" {...mouseAndKeyboardCallbackProps(() => navigate('/auth/login'))}>
              {t('forgot-password.buttons.back')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
